import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";

import {
  OrderReceiveInterface,
  OrderSendInterface,
  OrderInterface,
} from "types/Order";

import { OrderCard } from "modules/Order/Card";

import OrderAuctions from "./OrderAuctions";

import socket from "socket";

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<OrderInterface[]>([]);

  const orderReceiveUpdateItem = useCallback((upd: OrderReceiveInterface) => {
    setOrders((orders) => orders.map((p) => (p._id == upd._id ? upd : p)));
  }, []);

  const orderSendUpdateItem = useCallback((upd: OrderSendInterface) => {
    setOrders((orders) => orders.map((p) => (p._id == upd._id ? upd : p)));
  }, []);

  useEffect(() => {
    axios.get("/orders").then(({ data }) => {
      setOrders(data);
    });

    socket.on("order-receive_upd", orderReceiveUpdateItem);
    socket.on("order-send_upd", orderSendUpdateItem);

    return () => {
      socket.off("order-receive_upd", orderReceiveUpdateItem);
      socket.off("order-send_upd", orderSendUpdateItem);
    };
  }, []);

  return (
    <div className="  px-4 mt-4">
      <OrderAuctions />
      <h4 className="text-2xl">Заявки</h4>
      <div className="mt-4">
        <div className="grid items-center justify-between grid-cols-12 gap-2">
          {orders.map((r) => {
            return (
              <div key={r._id} className="lg:col-span-4 col-span-12">
                <OrderCard data={r} className="mb-3" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Orders;
